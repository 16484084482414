let iconBase = App.mediaPath + "icons/";

// Initialize and add the map
function initContactMap() {
  let el = document.getElementById("contactMap");
  const position = el.dataset.position.split(",");
  const lat = parseFloat(position[0]);
  const lng = parseFloat(position[1]);
  let contactMapCenterPosition = {
    lat: lat,
    lng: lng,
  };

  let map = new google.maps.Map(el, {
    zoom: 15,
    center: contactMapCenterPosition,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    //draggable: false,
    //zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  });

  let infowindow = new google.maps.InfoWindow({
    content: `<a href="https://maps.google.com/?q=${lat},${lng}" target="_blank">${el.dataset.title}</a>`,
  });

  let marker = new google.maps.Marker({
    position: contactMapCenterPosition,
    map: map,
    icon: iconBase + "map-marker.svg",
    animation: google.maps.Animation.DROP,
  });

  infowindow.open({
    anchor: marker,
    map,
    shouldFocus: false,
  });

  marker.addListener("click", () => {
    infowindow.open({
      anchor: marker,
      map,
      shouldFocus: false,
    });
  });
}

$(document).ready(function () {
  if ($("#contactMap").length) {
    initContactMap();
  }
});
