(function ($) {

  "use strict";

  function Common () {
    let bindSmoothScrollToElement = function () {
      // handle links with @href started with '#' only
      $(document).on('click', 'a[href^="#"]:not([data-toggle="tab"])', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top;

        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
      });
    };

    let bindShowMoreButton = function () {
      let $showMoreButton = $('.js-show-more-button');
      $showMoreButton.on('click', function () {
        $(this).toggleClass('is-close');
        $(this).closest('.js-show-more').find('.js-show-more-content').toggleClass('is-open');
      });
    };

    let bindHambuger = function ($header) {
      let $hamb = $('.js-hamburger', $header);
      let $navigation = $('.js-nav', $header);

      $hamb.on('click', function () {
        let $self = $(this);

        $self.find('> div').toggleClass('active');
        $('body').toggleClass('mobile-nav-active');
        $navigation.toggleClass('active');

        if ($navigation.hasClass('active')) {
          $('body').addClass('stop-scrolling');
          $('body').bind('touchmove', function(e){e.preventDefault()});
        } else {
          $('body').removeClass('stop-scrolling');
          $('body').unbind('touchmove');
        }
      });
    };

    let stickyNav = function ($header) {
      let $headerNav = $('.js-header-nav', $header);
      let $navWrapper = $('.js-nav-wrapper', $header);
      let navHeight = $navWrapper.outerHeight();
      let navFromTop = $navWrapper.offset().top;

      let $headerLogo = $('.js-header-logo', $header);
      let headerLogoHeight = $headerLogo.outerHeight();
      let headerLogoFromTop = $headerLogo.offset().top;

      function calculations (isResize) {
        var scrollTop = $(window).scrollTop();
        let navWidth;
        let headerLogoWidth;

        if (isResize) {
          navWidth = $navWrapper.css({
            'position': 'relative',
            'width': 'auto'
          }).width();
          headerLogoWidth = $headerLogo.css({
            'position': 'relative',
            'width': 'auto'
          }).width();
        } else {
          navWidth = $navWrapper.width();
          headerLogoWidth = $headerLogo.width();
        }

        if (DeviceSelector.desktop()) {
          if ($('body').hasClass('p-homepage')) {
            $navWrapper.toggleClass('is-sticky', scrollTop > navFromTop);
            $headerNav.css('padding-bottom', scrollTop > navFromTop ? navHeight : 0);
          } else {
            $navWrapper.toggleClass('is-sticky', scrollTop > navFromTop);
            $headerLogo.toggleClass('is-sticky', scrollTop > headerLogoFromTop);

            $('body').css('padding-top', scrollTop > headerLogoFromTop ? headerLogoHeight : 0);

            $navWrapper.css({
              'position': $navWrapper.hasClass('is-sticky') ? 'fixed' : 'relative',
              'right': 'auto',
              'width': navWidth
            });

            $headerLogo.css({
              'position': $navWrapper.hasClass('is-sticky') ? 'fixed' : 'relative',
              'left': 'auto',
              'width': headerLogoWidth
            });
          }
        } else {
          $headerLogo.toggleClass('is-sticky', scrollTop > headerLogoFromTop);
          $('.js-hamburger', $header).toggleClass('is-sticky', scrollTop > headerLogoFromTop);
          $('body').css('padding-top', scrollTop > headerLogoFromTop ? headerLogoHeight : 0);
        }
      }

      calculations();

      $(window).scroll(function () {
        calculations();
      });

      $(window).resize(function () {
        calculations(true);
      });
    };

    let toTheTop = function () {
      let $toTop = $('.js-to-top');

      function calcDistance () {
        $toTop.toggleClass('is-visible', $(window).scrollTop() > 200);
      }

      calcDistance();

      $(window).scroll(function () {
        calcDistance();
      });

      $toTop.on('click', function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
      });
    };

    let init = function (parentElement) {
      let $header = $('.js-header', parentElement);
      
      if ($header.length) {
        bindHambuger($header);
        stickyNav($header);
      }

      bindSmoothScrollToElement();
      bindShowMoreButton();
      toTheTop();
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Common = new Common();

})(jQuery);
